import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { ResponseZCore } from "../models/response-zcore";
import { StorageService } from "./storage.service";

interface IAuthObject {
  username: string;
  password: string;
}

@Injectable({
  providedIn: "root",
})
export class SsoAuthService {
  currentUrl: string = "";
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    this.currentUrl = state.url;
    try {
      const currentUser = await this.currentUser();
      if (
        (await this.storageService.get("erp-token")) &&
        (await this.storageService.get("@User"))
      ) {
        return true;
      } else {
        await this.router.navigate(["login"]);
        return false;
      }
    } catch (error) {
      if (this.currentUrl) this.router.navigate([`login${this.currentUrl}`]);
      else await this.router.navigate(["login"]);
    }
  }

  async login(user: IAuthObject) {
    const response: any = await lastValueFrom(
      this.http.post(`${environment.BASE_URL}/sso/auth`, {
        username: user.username,
        password: user.password,
      })
    );

    return response;
  }

  async logout(currentUrl) {
    Promise.all([
      this.storageService.remove("erp-token"),
      this.storageService.remove("@User"),
    ]);
    await this.router.navigate([`login${currentUrl}`]);
  }

  async verifyCustomerExistenceTaxNumber(cpfCnpj: string) {
    const response: any = await lastValueFrom(
      this.http.post(`${environment.BASE_URL}/public/is-customer`, {
        taxNumber: cpfCnpj,
      })
    );
    return response;
  }

  async currentUser(): Promise<any> {
    return await lastValueFrom(
      this.http.post<ResponseZCore>(
        environment.BASE_URL + "/sso/current-user",
        {}
      )
    );
  }

  async getEmailOtp(mail: string) {
    const response: any = await lastValueFrom(
      this.http.post(
        `${environment.BASE_URL}/public/register-mail-validation`,
        { mail }
      )
    );
    return response.data;
  }

  async getPhoneOtp(phone: string) {
    const response: any = await lastValueFrom(
      this.http.post(`${environment.BASE_URL}/public/register-sms-validation`, {
        phone,
      })
    );
    return response.data;
  }

  async validateEmailOtp(mail: string, code: string) {
    const response: any = await lastValueFrom(
      this.http.post(`${environment.BASE_URL}/public/is-valid-mail-code`, {
        mail,
        code,
      })
    );
    return response;
  }

  async validatePhoneOtp(phone: string, code: string) {
    const response: any = await lastValueFrom(
      this.http.post(`${environment.BASE_URL}/public/is-valid-sms-code`, {
        phone,
        code,
      })
    );
    return response.data;
  }

  async changePassword(body: { oldPassword: string; newPassword: string }) {
    const response: any = await lastValueFrom(
      this.http.post(
        `${environment.BASE_URL}/administrative/users/change-user-password`,
        body
      )
    );
    return response.data;
  }

  async verifyIsUser(username: string) {
    const response: any = await lastValueFrom(
      this.http.post(`${environment.BASE_URL}/sso/is-user`, { username })
    );
    return response;
  }
  async resetPassword(body: {
    newPassword: string;
    code: string;
    username: string;
  }) {
    const response: any = await lastValueFrom(
      this.http.post(
        `${environment.BASE_URL}/administrative/users/change-password`,
        body
      )
    );
    return response;
  }

  async forgotPassword(body: { username: string; phone: string }) {
    const response: any = await lastValueFrom(
      this.http.post(
        `${environment.BASE_URL}/administrative/users/forgot-password`,
        body
      )
    );
    return response;
  }

  async blockUser(hash: string) {
    const response: any = await lastValueFrom(
      this.http.post(`${environment.BASE_URL}/sso/block-user`, {
        hash,
      })
    );
    return response;
  }
}
