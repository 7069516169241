import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { WForgotPasswordPage } from "./modules/forgot-password/forgot-password";
import { SsoAuthService } from "./shared/services/sso-auth.service";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./modules/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./modules/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "login/:path/:id",
    loadChildren: () =>
      import("./modules/login/login.module").then((m) => m.LoginModule),
  },
  // {
  //   path: "abertura-conta",
  //   loadChildren: () =>
  //     import("./modules/contas/contas.module").then((m) => m.ContasModule),
  //   canActivate: [SsoAuthService],
  // },
  {
    path: "documentos/:id",
    loadChildren: () =>
      import("./modules/contas/contas.module").then((m) => m.ContasModule),
    canActivate: [SsoAuthService],
  },
  {
    path: "block-user/:id",
    loadChildren: () =>
      import("./modules/blocked-user/blocked-user.module").then(
        (m) => m.BlockedUserModule
      ),
  },
  {
    path: "emprestimo-pf/:id",
    loadChildren: () =>
      import("./modules/emprestimo-pf/emprestimo-pf.module").then(
        (m) => m.EmprestimoPfModule
      ),
    canActivate: [SsoAuthService],
  },
  {
    path: "emprestimo-pj/:id",
    loadChildren: () =>
      import("./modules/emprestimo-pj/emprestimo-pj.module").then(
        (m) => m.EmprestimoPjModule
      ),
    canActivate: [SsoAuthService],
  },
  {
    path: "investimento",
    loadChildren: () =>
      import("./modules/investimento/investimento.module").then(
        (m) => m.InvestimentoModule
      ),
    canActivate: [SsoAuthService],
  },
  {
    path: "user-validation",
    loadChildren: () =>
      import("./modules/user-validation/user-validation.module").then(
        (m) => m.UserValidationModule
      ),
  },
  {
    path: "user-validation/:path/:id",
    loadChildren: () =>
      import("./modules/user-validation/user-validation.module").then(
        (m) => m.UserValidationModule
      ),
  },
  {
    path: "w-forgot-password",
    component: WForgotPasswordPage,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
