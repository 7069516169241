// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  CLIENT_ID:
    "6af243c47ae213a5c711afa0a774e7eczcore85812898c6760fdc435756ebf295122c",
  ENV: "dsv",
  BASE_URL: "https://api.ibk.planiumbank.com.br/api",
  // BASE_URL: " http://localhost:3000/api",
  BASE_URL_CEP: "https://viacep.com.br/ws",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
